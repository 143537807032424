.hearings-table {
    border-collapse: collapse;
    width: 100%;
}

.hearings-table th,
.hearings-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.hearings-table th {
    background-color: #f2f2f2;
}

.hearings-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.hearings-table .event-label {
    cursor: pointer;
}

.hearings-table .event-label:hover {
    color: #555555;
}

.hearings-notfound {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    color: #888;
}
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    height: calc(100vh - 20px);
}
.content.dark {
    color: #FFFFFF;
    background-color: #222222;
}
.content.light {
    color: #222222;
    background-color: white;
}

header, footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    min-height: 42px;
}

header div, footer div {
    display: flex;
}

.search-area {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;

    background-color: #FFFFFF;
    color: #222222;

    border-radius: 18px;
    height: 25px;
}
.light .search-area {
    border: 1px solid #dfe1e5;
}
.light .search-area:hover {
    box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
    border-color: transparent;
}
.search-area input {
    border: none;
    width: 300px;
    font-family: inherit;
}

.search-area input:focus {
    outline: none;
}

.actionable {
    cursor: pointer;
}
.actionable:hover {
    filter: brightness(85%);
}

.statistic {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}
.statistic .widget {
    flex: 1;
    padding: 15px;
    border: 1px solid #bcbec1;
    border-radius: 9px;

    height: 300px;
}
.statistic .widget h1 {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
}
.light .statistic .widget h1 {
    color: #222222;
}
.dark .statistic .widget h1 {
    color: #ffffff;
}

.statistic .widget hr {
    border-width: 0.05px;
    margin: 12px;
}
.statistic-details td {
    font-size: 14px;
}
table {
    width: 100%;
}
thead tbody {
    display: flex;
    justify-content: space-between;
}
th {
    text-align: start;
    font-weight: 500;
}

.td-icon {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.light .td-icon {
    color: #5f6368ff
}
.dark .td-icon {
    color: #b0b8c2;
}
.receipts-table-container {
    flex: 1;
}
.receipts-table .align-right {
    text-align: end;
}
.receipts-table .align-center {
    text-align: center;
}
.receipts-table th {
    text-transform: capitalize;
}

.settings.form {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}
.settings.form-item {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.dark .popup-container {
    color: #FFFFFF;
    background-color: #222222;
}

.light .popup-container {
    color: #222222;
    background-color: white;
}

  
.settings.form label {
    font-weight: bold;
    margin-bottom: 5px;
}

.settings.form select,
.settings.form input[type="number"] {
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-size: 16px;
}
.settings.form select:focus,
.settings.form input:focus {
    outline: none;
}

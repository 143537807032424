header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    min-height: 42px;
}

header div {
    display: flex;
}

.sh-search {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;

    background-color: #FFFFFF;
    color: #222222;

    border-radius: 18px;
    height: 25px;
}
.sh-search {
    border: 1px solid #dfe1e5;
}
.sh-search:hover {
    box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
    border-color: transparent;
}

.sh-search input {
    border: none;
    width: 300px;
    font-family: inherit;
}

.sh-search input:focus {
    outline: none;
}

.actionable {
    cursor: pointer;
}
.actionable:hover {
    filter: brightness(85%);
}

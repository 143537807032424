.claim-details {
    margin: 1rem;
}

.hover-effect {
    cursor: pointer;
    transition: transform 0.1s ease;
}
.hover-effect:hover {
    transform: scale(1.05);
}

.claim-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.claim-details-header .label {
    font-size: 1.5rem;
    font-weight: bold;
}
.claim-details-header .status {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}
.claim-details-header .status-label {
    text-transform: uppercase;
}
.claim-details-header .status-action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 0.2rem;
}
.claim-details-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.claim-details-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
}
.horizontal-f {
    display: flex;
    gap: 16px;
}
.horizontal-s {
    display: flex;
    gap: 15px;
}
.description-area {
    flex: 2;
    display: flex;
    flex-direction: column;
}
.participants-area {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.content-area {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.content-area-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-bottom: 8px;
}
.header-title {
    font-size: 1.2em;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
}
.header-action {
    cursor: pointer;
}
.content-area-children {
    padding: 8px;
}
.content-area p {
    margin: 4px 0;
    padding: 0;
}
.description-p {
    font-weight: normal;
}
.bold {
    font-weight: bold;
}
.header-p {
    text-align: right;
}
.date-p {
    text-align: center;
}

.claim-details-person {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #f9f9f9;
}
.person-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.person-name {
    font-size: 1.2rem;
    font-weight: bold;
}
.person-action {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.person-details {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.person-phone,
.person-discord {
    font-size: 1rem;
    color: #666;
}
.person-action:hover {
    transform: scale(1.1);
    transition: transform 0.1s ease;
}
.claim-details-evidence {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.action-popup {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
}
.action-popup.show {
    display: flex;
}
.popup-container {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    max-height: 90vh;
}
.popup-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.popup-header .header-action {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    display: flex;
}
.popup-header .header-action:hover {
    color: rgb(180, 0, 0);
}
.popup-content {
    overflow-y: auto;
    padding-right: 15px;
}

.lawsuit-defendant-input {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}
.claim-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    cursor: pointer;
}

.claim-card:hover {
    background-color: #eee;
}

.claim-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.claim-card-header div:first-child {
    font-weight: bold;
}

.claim-card-description {
    color: #666666;
}

.claim-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.claims-list {
    overflow-y: auto;
    padding: 10px;

}
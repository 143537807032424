form input,
form select,
form .file-upload {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

form select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
}

form select:disabled {
    opacity: 1;
    background-color: #e9ecef;
}

form .group {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

form .group.vertical {
    flex-direction: column;
    justify-content: start;
}

form .field {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

form img {
    width: 100%;
}

form button {
    background-color: #3e72ff;
    color: #e9ecef;
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
}

form .file-upload:hover {
    background-color: #e9ecef;
}
.form-container {
    display: flex;
    flex-direction: column;
}
.form-container label {
    margin-top: 10px;
}
.form-container input,
.form-container select {
    margin-top: 5px;
    padding: 5px;
    font-size: 16px;
}
.form-container button {
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.form-container button:hover {
    background-color: darkblue;
}
.person-list {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}
.person-item {
    padding: 10px;
    cursor: pointer;
    background-color: rgb(220, 220, 220);
    margin-bottom: 5px;
}
.person-item:hover {
    background-color: rgb(200, 200, 200);
}

.button-area {
    margin: 5px;
    gap: 10px;
    display: flex;
    margin-top: 20px;
}
.lawsuit-footer {
    display: flex;
    padding: 5px;
}

.lawsuit-form label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.lawsuit-form select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
}

.lawsuit-form select, .lawsuit-form input:not(.warning-status), .lawsuit-form textarea {
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
    width: 98%;
}



textarea {
    height: 100px;
    resize: none;
}

input[disabled], textarea[disabled] {
    background-color: #e9ecef;
}


.buttoned-label, .buttoned-data .buttoned-item {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.buttoned-data .buttoned-item input {
    flex: 1;
}
.warning {
    display: flex;
    align-items: center;
}
.warning-status {
    width: 50px;
}
.warning.critical {
    color: rgb(240, 10, 10);
}
.warning.miscellaneous {
    color: rgb(200, 130, 0);
}
.warning.informative {
    color: rgb(15, 80, 210);
}
.warning-status {
    width: 30px;
}
.comparison {
    margin: 15px 0px;
}
.comparison .old {
    color: rgb(240, 10, 10);
}
.comparison .new {
    color: rgb(0, 150, 0);
}
.login-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.login-button {
    color: #fff;
    background-color: #0684d8;

    width: 10rem;
    height: 3.5rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 10px;

    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
}
  
.login-button:hover {
    background-color: #055fa5;
}
  
.login-button:active {
    background-color: #03497a;
}
.icon-button-container {
    display: flex;
    padding: 1px;
}
.icon-button {
    margin: 0rem 0.2rem;
    padding: 0.4rem 0.6rem; 
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    color: white;
    background-color: #3e72ff;
}
.icon-button-small {
    margin: 0rem 0.2rem;
    padding: 0.2rem 0.3rem; 
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    color: white;
    background-color: #3e72ff;
}
.icon-button .label, .icon-button-small .label {
    text-transform: uppercase;
    font-size: 15px;
    color: white;
}
.icon-button .icon, .icon-button-small .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
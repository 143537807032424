/* General Container Styles */
.container {
    display: flex;
    height: 100vh;
}

/* Flex Columns */
.column-left {
    flex: 0.4;
    padding: 1rem;
    height: calc(100vh - 2rem);
    display: flex;
    flex-direction: column;
    position: relative;
}

.column-right {
    flex: 1;
    padding: 0rem 1rem;
    overflow-y: scroll;
    height: 100vh;
}

/* Sticky Header */
.header {
    background-color: white;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 1rem 0;
}

/* Search Field */
.search-field {
    flex-grow: 1;
    padding: 0.5rem;
    margin: 0 1rem;
}

/* Create Button */
.create-button {
    padding: 1rem;
    background-color: #007bff;
    color: white;
    text-align: center;
    cursor: pointer;
    position: sticky;
    bottom: 0;
    z-index: 1;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #0056b3;
}

/* Icon Styles */
.pointer {
    cursor: pointer;
}

.back-icon,
.flag-icon {
    cursor: pointer;
}

/* List Styles */
.list {
    overflow-y: scroll;
    padding: 0 4px;
    height: calc(100vh - 2rem);
}

/* Details Header */
.details-header {
    position: sticky;
    top: 0;
    background: white;
}
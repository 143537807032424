.claim-item {
    padding: 10px;
    margin: 10px 0;
    position: relative;
}
.claim-item:hover {
    cursor: pointer;
}
.claim-item::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: inherit;
    opacity: 0;
    transition: opacity 0.1s;
}
.claim-item:hover::before {
    opacity: 0.75;
}
.claim-item-header {
    display: flex;
    justify-content: space-between;
}
.claim-item-label {
    font-weight: bold;
}
.claim-item-time {
    font-style: italic;
}
.claim-item-body {
    margin-top: 8px;
    margin-bottom: 6px;
}
.claim-item-description {
    margin: 0;
}
.claim-item-client {
    color: rgba(80, 80, 80, 0.5)
}
.claim-item-footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.claim-item-action {
    height: 32px;
    width: 32px;
}
.claim-item-action:hover {
    transform: scale(1.2);
}

.c1 {
    background-color: rgba(176, 176, 176, 0.25);
}
.c2 {
    background-color: rgba(55, 162, 28, 0.25);
}
.c3 {
    background-color: rgba(255, 107, 53, 0.25);
}
.c4 {
    background-color: rgba(0, 123, 179, 0.25);
}
.c5 {
    background-color: rgba(158, 0, 198, 0.25);
}
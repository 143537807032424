.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #0684d8;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

.loader-small-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loader-small {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0684d8;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.create-claim-form {
    display: flex;
    flex-direction: column;
}

.create-claim-form label {
    margin-top: 10px;
}

.create-claim-form input,
.create-claim-form select {
    margin-top: 5px;
    padding: 5px;
    font-size: 16px;
}

.create-claim-form button {
    margin-top: 20px;
    padding: 10px;
    font-size: 16px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.create-claim-form button:hover {
    background-color: darkblue;
}
.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    justify-content: center;
    align-items: center;
}

.modal-container {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    max-height: 90vh;
}
.modal.show {
    display: flex;
}
.modal-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.modal-content {
    overflow-y: auto;
    padding-right: 15px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
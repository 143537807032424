:root {
    --main-bg-color: aliceblue;
    --primary-color: #3e72ff;
    --avatar-color: #000000;
    --avatar-size: 3rem;
    --heading-font-size: 2em;
    --heading-font-weight: bold;
    --padding-small: 5px;
    --padding-medium: 10px;
    --gap-small: 5px;
    --container-width: 52rem;
    --navigation-width: 18rem;
    --bar-height: 3rem;
}

/* General Layout */
.hp-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.hp-container {
    display: flex;
    justify-content: center;
    gap: var(--gap-small);
    height: 90vh;
    overflow-y: hidden;
    padding: var(--padding-medium);
}

/* Custom Scrollbar */
.hp-container::-webkit-scrollbar {
    width: 8px;
}

.hp-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.hp-container::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 10px;
}

/* hp Navigation */
.hp-navigation {
    width: var(--navigation-width);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.hp-options {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
}
.hp-option {
    padding: var(--padding-medium);
    display: flex;
    cursor: pointer;
    transition: background-color 0.15s;
}
.hp-option:hover, .hp-option.active {
    background-color: var(--main-bg-color);
}

.hp-option .material-symbols, .hp-logout .material-symbols {
    display: flex;
    align-items: center;
}
.hp-option .material-symbols {
    margin-right: var(--padding-small);
}
.hp-logout {
    display: flex;
    cursor: pointer;
}

.hp-logout:hover {
    color: darken(var(--primary-color), 10%);
}

.hp-footer {
    display: flex;
    justify-content: center;
    color: var(--primary-color);
    padding-top: var(--padding-small);
}

/* hp Display */
.hp-display {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: var(--container-width);
    padding: var(--padding-medium);
}

.hp-content {
    flex: 1;
}

.hp-vertical-box {
    display: flex;
    height: 100%;
    flex-direction: column;
}

/* hp Bar */
.hp-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-medium);
    height: var(--bar-height);
}

/* hp Info */
.hp-info {
    display: flex;
    align-items: center;
}

.hp-avatar {
    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 50%;
    margin-right: var(--padding-small);
    border: 2px solid var(--avatar-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hp-details {
    margin-left: var(--padding-small);
}

.hp-contacts {
    display: flex;
    gap: var(--gap-small);
    padding-top: var(--padding-small);
}

.hp-name {
    font-weight: var(--heading-font-weight);
}

/* hp Heading */
.hp-heading {
    font-size: var(--heading-font-size);
    font-weight: var(--heading-font-weight);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding-medium);
    border-bottom: 2px solid var(--primary-color);
}

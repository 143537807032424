.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.error-gif {
    height: 500px;
}

.back-to-home {
    margin-top: 20px;
    font-size: 18px;
    color: #007bff;
    text-decoration: none;
}

.back-to-home:hover {
    text-decoration: underline;
}